import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/Login"
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import(/* webpackChunkName: "about" */ '../views/userAdmin/map.vue')
  },
  {
    // shwoPdf pdf预览
    path: '/downLoadPdf',
    name: 'downLoadPdf',
    component: () => import('../views/userAdmin/downLoadPdf.vue'),
    meta: {
      title: "首页",
      need_token: true,
      permission: ["1", "2", "3", "4", "5"]
    }
  },
  {
    path: '/Index',
    name: 'Index',
    component: () => import('../layout/index.vue'),
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('userToken')) {
        next()
      } else {
        next('/')
        // next()
      }
    },
    children: [
      {
        // userList 用户列表
        path: '/userList',
        name: 'userList',
        component: () => import('../views/userAdmin/userList.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('userToken')) {
            next()
          } else {
            next('/')
            // next()
          }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        path: '/iframe',
        name: 'iframe',
        component: () => import('../views/iframe/iframe.vue'),
        meta: {
          title: "iframe",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        // orderList 订单列表
        path: '/orderList',
        name: 'orderList',
        component: () => import('../views/userAdmin/orderList.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('userToken')) {
            next()
          } else {
            next('/')
            // next()
          }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        // goodsList 产品列表
        path: '/goodsList',
        name: 'goodsList',
        component: () => import('../views/userAdmin/goodsList.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('userToken')) {
            next()
          } else {
            next('/')
            // next()
          }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
      {
        // goodsList 产品列表
        path: '/inviteList',
        name: 'inviteList',
        component: () => import('../views/userAdmin/inviteList.vue'),
        beforeEnter: (to, from, next) => {
          if (localStorage.getItem('userToken')) {
            next()
          } else {
            next('/')
            // next()
          }

        },
        meta: {
          title: "首页",
          need_token: true,
          permission: ["1", "2", "3", "4", "5"]
        }
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
